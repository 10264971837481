import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { Modal } from "../components";

type Props = {
  children?: ReactNode;
};

export default function BackEndLayout(props: Props) {
  const user = sessionStorage.getItem("user");
  const login = sessionStorage.getItem("login");
  const isLogin = user && login ? true : false;
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const navItem = [
    {
      title: "products",
      link: "/products",
    },
    {
      title: "brands",
      link: "/brands",
    },
    {
      title: "categories",
      link: "/categories",
    },
  ];
  if (isLogin === false) {
    return <Navigate to="/backend-login" />;
  }
  return (
    <>
      <Helmet>
        <title>Trust Zone</title>
      </Helmet>
      <main className="flex min-h-screen flex-col">
        <div className="navbar w-full bg-slate-200 py-1 md:py-3">
          <div className="navbar-start"></div>
          <div className="navbar-center flex gap-2 md:gap-3 justify-center">
            {navItem.map((item, index) => {
              const btnActive = item.link.toLowerCase() === path.toLowerCase() ? "btn-primary" : "";
              return (
                <Link key={index} to={item.link} className={`btn btn-xs md:btn-sm ${btnActive}`}>
                  {item.title}
                </Link>
              );
            })}
          </div>
          <div className="navbar-end  gap-2 text-sm text-end  md:gap-4 md:text-base pr-3">
            <Link target="_blank" to="" className="underline text-primary">
              view website
            </Link>
            <button onClick={() => setShowSignOutModal(true)} className="font-semibold text-error">
              <FontAwesomeIcon title="Logout" icon={faSignOut} />
            </button>
          </div>
        </div>
        <div className="flex-1">
          {children}
          <Outlet />
        </div>
      </main>
      <Modal open={showSignOutModal} onClose={() => setShowSignOutModal(false)}>
        <h3 className="text-error text-lg md:text-xl font-semibold">Sign out</h3>
        <p className="text-center my-3 font-semibold">Are you sure want to sign out ?</p>
        <div className="flex gap-3 justify-end">
          <button
            onClick={() => {
              sessionStorage.clear();
              navigate("");
            }}
            className="btn btn-sm btn-error text-white"
          >
            Yes
          </button>
          <button onClick={() => setShowSignOutModal(false)} className="btn btn-sm btn-outline">
            No
          </button>
        </div>
      </Modal>
    </>
  );
}
