import { useNavigate } from "react-router-dom";
import { Input, InputError, Loading } from "../../../components";
import { useEffect, useState } from "react";
import { request } from "../../../lib";
export default function NewProduct() {
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const [model, setMode] = useState("");
  const [price, setPrice] = useState<number | "">("");
  const [category, setCategory] = useState<number | undefined>(undefined);
  const [brand, setBrand] = useState<number | undefined>(undefined);
  const [image, setImage] = useState<File | null>(null);
  const [saveError, setSaveError] = useState(false);
  const [note, setNote] = useState("");

  const [specifications, setSpecifications] = useState<{ [index: string]: any }>({});
  const [specList, setSpecList] = useState<Specification[]>([]);

  const [selectCategoryOption, setSelectCategoryOption] = useState<Options[]>();
  const [selectBrandOption, setSelectBrandOption] = useState<Options[]>();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      //get select brands option
      await request({ url: "brands" }).then((res) => {
        const option: Options[] = [];
        const brands: Brands[] = res.data;
        brands.forEach((brand) =>
          option.push({
            label: brand.brand,
            value: brand.id,
          }),
        );
        setSelectBrandOption(option);
      });
      //get select category option
      await request({ url: "categories" }).then((res) => {
        const option: Options[] = [];
        const categories: Categories[] = res.data;
        categories.forEach((category) =>
          option.push({
            label: category.category,
            value: category.id,
          }),
        );
        setSelectCategoryOption(option);
      });
      setReady(true);
    })();
  }, []);

  function save() {
    if (!model || !code || !price || !brand || !category || !image) {
      setSaveError(true);
      return;
    }
    request({
      method: "POST",
      url: "products",
      data: { model, code, price, brand, image, category, specifications: JSON.stringify(specifications), note },
      mulitimedia: true,
    });
    navigate("/products");
  }
  if (!ready) return <Loading />;

  return (
    <div className="mx-3 md:mx-5 lg:mx-8">
      <h3 className="text-lg md:text-2xl font-semibold my-3 text-center">Add Product</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 ">
        <Input label="Model" required value={model} onChange={(e) => setMode(e.target.value)} />
        <Input label="Code" required value={code} onChange={(e) => setCode(e.target.value)} />
        <Input
          type="number"
          label="Price"
          required
          value={price}
          onChange={(e) => setPrice(Number(e.target.value) ? Number(e.target.value) : "")}
        />
        <Input
          type="select"
          options={selectBrandOption}
          label="Brand"
          required
          value={brand}
          onChange={(e) => setBrand(Number(e.target.value))}
        />
        <Input
          type="image"
          label="Image"
          required
          onChange={(e) => {
            const event = e as React.ChangeEvent<HTMLInputElement>;
            const files = event.target.files;
            if (files) {
              setImage(files[0]);
            }
          }}
        />
        <Input
          type="select"
          options={selectCategoryOption}
          label="Category"
          required
          value={category}
          onChange={(e) => {
            setCategory(Number(e.target.value));
            setSpecList([]);
            request({ url: "specifications/categories/" + e.target.value }).then((res) => {
              setSpecList(res.data);
              const lists: Specification[] = res.data;
              lists.forEach((item) => {
                setSpecifications((current) => {
                  return { ...current, [item.specification]: "" };
                });
              });
            });
          }}
        />
        {specList.map((spec, index) => (
          <Input
            key={index}
            label={spec.specification}
            value={specifications[spec.specification]}
            onChange={(e) => {
              setSpecifications((current) => {
                return { ...current, [spec.specification]: e.target.value };
              });
            }}
          />
        ))}
        <Input type="textarea" label="Note" value={note} onChange={(e) => setNote(e.target.value)} rows={5} />
      </div>

      <InputError show={saveError} />
      <div className="flex gap-3 justify-center">
        <button onClick={save} className="btn btn-primary">
          Save
        </button>
        <button onClick={() => navigate("/products")} className="btn btn-error text-white">
          Cancel
        </button>
      </div>
    </div>
  );
}
