import axios from "axios";

// const base_url = "http://localhost:9000";
const base_url = "http://ccccomputer.trustzonecambodia.com";

export async function request({
  method = "GET",
  url,
  params,
  data,
  mulitimedia,
}: {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  url: string;
  params?: {
    [index: string]: any;
  };
  data?: {
    [index: string]: any;
  };
  mulitimedia?: boolean;
}) {
  let content_type: string = "application/json";
  let formData;
  if (mulitimedia) {
    content_type = "multipart/form-data";
    formData = new FormData();
    if (data) {
      for (const [key, value] of Object.entries(data!)) {
        formData.append(key, value);
      }
    }
  }

  const result = await axios({
    method,
    params,
    url: `${base_url}/${url}`,
    data: formData ? formData : data,
    headers: {
      "Content-Type": content_type,
      Authorization: sessionStorage.getItem("user"),
    },
  });

  return result;
}

export function khmer_number(text: string) {
  let result = text.toString();
  result = result.replaceAll("0", "០");
  result = result.replaceAll("1", "១");
  result = result.replaceAll("2", "២");
  result = result.replaceAll("3", "៣");
  result = result.replaceAll("4", "៤");
  result = result.replaceAll("5", "៥");
  result = result.replaceAll("6", "៦");
  result = result.replaceAll("7", "៧");
  result = result.replaceAll("8", "៨");
  result = result.replaceAll("9", "៩");
  return result;
}

export function get_server_image(filename: string) {
  return base_url + "/public/" + filename;
}

export function static_asset(filepath: string) {
  return process.env.PUBLIC_URL + filepath;
}
