import { Specification } from ".";
import { get_server_image } from "../lib";

export default function ProductCard(props: Products) {
  const { price, model, image, note, id, code } = props;

  return (
    <div className="border-red-500 border-[1px] p-3">
      <div className="w-[100%] h-[140px] md:h-[160px]">
        <img loading="lazy" className="w-[100%] h-[100%]" src={get_server_image(image)} alt="Product" />
      </div>
      <div className="text-blue-900 font-bold text-center text-sm">{model}</div>
      <div className="text-red-600 font-bold text-center text-xl">${price}</div>
      <div className="text-xs">
        <Specification productID={id} />
        {code && (
          <div className="text-center border-2 border-dashed border-black my-2 py-1">
            <span className="font-bold">Code : </span>
            <span className="font-semibold">{code}</span>
          </div>
        )}
      </div>
      {note && (
        <div className="">
          {note.split(",").map((n, index) => (
            <div key={index} className="text-xs font-semibold">
              - {n}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
