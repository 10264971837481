import { useEffect, useState } from "react";

import { request } from "../lib";
import Loading from "./loading";
type Props = {
  productID: number;
};
export default function Specifaction(props: Props) {
  const { productID } = props;
  const [specList, setSpecList] = useState<ProductSpecification[]>([]);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (productID) {
      request({ url: "products/specifications/" + productID })
        .then((res) => setSpecList(res.data))
        .finally(() => setReady(true));
    } else {
      setReady(true);
    }
  }, [productID]);
  if (!ready) return <Loading height="100px" />;
  return (
    <>
      {specList.map((spec, index) => (
        <div key={index} className="flex gap-1">
          <div className="font-semibold flex gap-1">
            <span>-</span>
            <span>{spec.spec_label}</span>
            <span>:</span>
          </div>
          <div className="grow">{spec.spec_value}</div>
        </div>
      ))}
    </>
  );
}
