type Props = {
  show: boolean;
  text?: string;
  align?: "start" | "center" | "end";
};
export default function InputError(props: Props) {
  const { show, text = "* field is required.", align = "end" } = props;
  if (!show) return <></>;
  return <div className={`text-error font-semibold text-${align} my-3`}>{text}</div>;
}
