import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./pages/hompage";
import Login from "./pages/backend/login";
import Products from "./pages/backend/products";
import EditProduct from "./pages/backend/products/edit";
import NewProduct from "./pages/backend/products/new";
import Brands from "./pages/backend/brands";
import Categories from "./pages/backend/categories";
import BackEndLayout from "./layout/back-end-layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Homepage />} />
        <Route path="/backend-login" element={<Login />} />
        <Route element={<BackEndLayout />}>
          <Route path="/products" element={<Products />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/products/new" element={<NewProduct />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/categories" element={<Categories />} />
        </Route>
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

