import { useRef } from "react";

type Props = {
  children: React.ReactNode;
  open: boolean;
  size?: "sm" | "md" | "lg";
  full?: boolean;
  onClose: () => void;
};

export default function Modal(props: Props) {
  const { open, children, onClose, size = "sm", full = false } = props;

  let width = "xl";
  let padding = "px-4 py-6";
  if (size === "md") width = "4xl";
  if (size === "lg") width = "7xl";
  if (full) {
    padding = "p-0";
  }
  const ref = useRef<HTMLDialogElement>(null);

  open ? ref.current?.showModal() : ref.current?.close();

  return (
    <>
      <dialog className="modal" ref={ref}>
        <div className={`modal-box w-11/12 max-w-${width} ${padding} backdrop:rounded-none`}>{children}</div>
        <form method="dialog" className="modal-backdrop">
          <button onClick={onClose}>close</button>
        </form>
      </dialog>
    </>
  );
}
