type Props = {
  height?: string;
};

export default function Loading(props: Props) {
  const { height = "200px" } = props;

  return (
    <div className={`h-[${height}] grid place-content-center`}>
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  );
}
