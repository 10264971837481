import { ConfirmDelete, Input, InputError, Loading, Modal } from "../../../components";
import { useEffect, useRef, useState } from "react";
import { request } from "../../../lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function Categories() {
  const [newCategory, setNewCategory] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [newSpec, setNewSpec] = useState("");
  const [editSpec, setEditSpec] = useState("");
  const [saveError, setSaveError] = useState(false);

  const [categories, setCategories] = useState<Categories[]>([]);
  const [specList, setSpectList] = useState<Specification[]>([]);

  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSpecModal, setShowSpecModal] = useState(false);
  const [showNewSpecModal, setShowNewSpecModal] = useState(false);
  const [showEditSpectModal, setShowEditSpecModal] = useState(false);

  const [ready, setReady] = useState(false);

  const selectedCategoryRef = useRef<number>(0);
  const selectedSpecRef = useRef<number>(0);

  useEffect(() => {
    request({ url: "categories" })
      .then((res) => setCategories(res.data))
      .finally(() => setReady(true));
  }, []);

  function saveNew() {
    if (!newCategory) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    request({ method: "POST", url: "categories", data: { category: newCategory } })
      .then((res) => setCategories((current) => [...current, res.data]))
      .finally(() => {
        setNewCategory("");
        setShowNewModal(false);
      });
  }
  function saveEdit() {
    if (!editCategory) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    request({ method: "PUT", url: "categories/" + selectedCategoryRef.current, data: { category: editCategory } })
      .then((res) => {
        const index = categories.findIndex((category) => category.id === selectedCategoryRef.current);
        if (index > 0) {
          const updateData: Categories = {
            id: selectedCategoryRef.current,
            category: editCategory,
          };
          setCategories((current) => [...current.slice(0, index), updateData, ...current.slice(index + 1)]);
        }
      })
      .finally(() => {
        setEditCategory("");
        setShowEditModal(false);
      });
  }

  function saveDelete(id: number | undefined) {
    if (!id) return;
    request({ method: "DELETE", url: "categories/" + id }).then((res) => setCategories((current) => current.filter((b) => b.id !== id)));
  }

  // spec

  function showSpect() {
    request({ url: "specifications/categories/" + selectedCategoryRef.current })
      .then((res) => setSpectList(res.data))
      .finally(() => setShowSpecModal(true));
  }
  function saveNewSpec() {
    if (!newSpec) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    request({ method: "POST", url: "specifications", data: { category_id: selectedCategoryRef.current, specification: newSpec } })
      .then((res) => setSpectList((current) => [...current, res.data]))
      .finally(() => {
        setNewSpec("");
        setShowNewSpecModal(false);
      });
  }

  function saveEditSpec() {
    if (!editSpec) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    request({ method: "PUT", url: "specifications/" + selectedSpecRef.current, data: { specification: editSpec } })
      .then((res) => {
        const index = specList.findIndex((spec) => spec.id === selectedSpecRef.current);
        if (index > 0) {
          const updateSpec: Specification = { ...specList[index], specification: editSpec };
          setSpectList((current) => [...current.slice(0, index), updateSpec, ...current.slice(index)]);
        }
      })
      .finally(() => {
        setEditSpec("");
        setShowEditSpecModal(false);
      });
  }

  function deleteSpec(specID: number) {
    request({ method: "DELETE", url: "specifications/" + specID }).finally(() =>
      setSpectList((current) => current.filter((spec) => spec.id !== specID)),
    );
  }
  if (!ready) return <Loading />;
  return (
    <section className="px-3 sm:px-0 sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto">
      <h3 className="font-semibold text-lg md:text-2xl text-center my-3">Categories</h3>
      <div className="text-end">
        <button onClick={() => setShowNewModal(true)} className="btn btn-sm btn-primary my-3">
          Addnew
        </button>
        <div className="overflow-x-auto mt-3">
          <table className="table table-zebra">
            <thead className="bg-primary text-white text-lg capitalize">
              <tr>
                <td>No</td>
                <td>Category</td>
                <td>Specification</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {categories.length === 0 && (
                <tr>
                  <td colSpan={4} align="center" className="font-semibold">
                    No data
                  </td>
                </tr>
              )}
              {categories.map((category, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{category.category}</td>
                  <td>
                    <button
                      onClick={() => {
                        selectedCategoryRef.current = category.id;
                        showSpect();
                      }}
                      className="text-primary underline font-semibold"
                    >
                      view
                    </button>
                  </td>
                  <td className="flex gap-3">
                    <button
                      className="btn btn-xs btn-primary"
                      onClick={() => {
                        selectedCategoryRef.current = category.id;
                        setEditCategory(category.category);
                        setShowEditModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <ConfirmDelete className="btn btn-xs btn-error" onDelete={() => saveDelete(category.id)}>
                      <FontAwesomeIcon className="text-white" icon={faTrash} />
                    </ConfirmDelete>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* new modal */}
      <Modal open={showNewModal} onClose={() => setShowNewModal(false)}>
        <h3 className="mb-3 font-semibold text-center text-lg md:text-2xl">New Category</h3>
        <Input label="Category" value={newCategory} onChange={(e) => setNewCategory(e.target.value)} required />
        <InputError show={saveError} />
        <div className="flex justify-end gap-3">
          <button onClick={saveNew} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowNewModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>

      {/* edit modal */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <h3 className="mb-3 font-semibold text-center text-lg md:text-2xl">New Category</h3>
        <Input label="Category" value={editCategory} onChange={(e) => setEditCategory(e.target.value)} required />
        <InputError show={saveError} />
        <div className="flex justify-end gap-3">
          <button onClick={saveEdit} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowEditModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>

      {/* specification modal */}
      <Modal open={showSpecModal} onClose={() => setShowSpecModal(false)}>
        <div className="flex justify-between mb-3">
          <h3 className="text-center font-semibold text-lg md:text-2xl">Specification list</h3>
          <button onClick={() => setShowNewSpecModal(true)} className="btn btn-xs btn-primary">
            AddNew
          </button>
        </div>
        {specList.length === 0 && <div className="text-center my-3">Specification not set yet.</div>}
        {specList.map((spec, index) => (
          <div className="flex gap-3" key={index}>
            <p>- {spec.specification}</p>
            <button
              onClick={() => {
                selectedSpecRef.current = spec.id;
                setEditSpec(spec.specification);
                setShowEditSpecModal(true);
              }}
            >
              <FontAwesomeIcon className="text-primary" icon={faEdit} />
            </button>
            <ConfirmDelete onDelete={() => deleteSpec(spec.id)}>
              <FontAwesomeIcon className="text-error" icon={faTrash} />
            </ConfirmDelete>
          </div>
        ))}
        <div className="text-end mt-3">
          <button onClick={() => setShowSpecModal(false)} className="btn btn-sm btn-outline">
            Close
          </button>
        </div>
      </Modal>

      {/* model new Spect */}
      <Modal open={showNewSpecModal} onClose={() => setShowNewSpecModal(false)}>
        <h3 className="text-lg md:text-2xl font-semibold text-center my-3">Add New Specification</h3>
        <Input label="Specification" required value={newSpec} onChange={(e) => setNewSpec(e.target.value)} />
        <InputError show={saveError} />
        <div className="flex gap-3 justify-end my-3">
          <button onClick={saveNewSpec} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowNewSpecModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>

      {/* model edit Spect */}
      <Modal open={showEditSpectModal} onClose={() => setShowEditSpecModal(false)}>
        <h3 className="text-lg md:text-2xl font-semibold text-center my-3">Edit Specification</h3>
        <Input label="Specification" required value={editSpec} onChange={(e) => setEditSpec(e.target.value)} />
        <InputError show={saveError} />
        <div className="flex gap-3 justify-end my-3">
          <button onClick={saveEditSpec} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowEditSpecModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>
    </section>
  );
}
