import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ConfirmDelete, Input, Loading, Modal, Specification } from "../../../components";
import { get_server_image, request } from "../../../lib";

export default function Products() {
  const [searchCode, setSearchCode] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [products, setProducts] = useState<Products[]>([]);

  const [selectBrandOption, setSelectBrandOption] = useState<Options[]>([]);
  const [selectCategoryOption, setSelectCategoryOption] = useState<Options[]>([]);

  const [showImageModal, setShowImageModal] = useState(false);
  const [showConfirmCopy, setShowConfirmCopy] = useState(false);
  const [ready, setReady] = useState(false);
  const imageSourceRef = useRef<string>("");
  const productIdRef = useRef<number | null>(null);
  const navigate = useNavigate();

  function getData() {
    setReady(false);
    request({ url: "products", params: { brand: searchBrand, code: searchCode, model: searchModel, category: searchCategory } })
      .then((res) => setProducts(res.data))
      .finally(() => setReady(true));
  }
  useEffect(() => {
    (async () => {
      //get select brand option
      await request({ url: "brands" }).then((res) => {
        const option: Options[] = [];
        const brands: Brands[] = res.data;
        brands.forEach((brand) =>
          option.push({
            label: brand.brand,
            value: brand.id,
          }),
        );
        setSelectBrandOption(option);
      });
      //get select category option
      await request({ url: "categories" }).then((res) => {
        const option: Options[] = [];
        const categories: Categories[] = res.data;
        categories.forEach((category) =>
          option.push({
            label: category.category,
            value: category.id,
          }),
        );
        setSelectCategoryOption(option);
      });
      getData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return <Loading />;
  return (
    <section className="px-3">
      <div className="grid gap-3 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-3 md:py-5 mb-3 ">
        <Input label="Code" onChange={(e) => setSearchCode(e.target.value)} value={searchCode} />
        <Input label="Model" onChange={(e) => setSearchModel(e.target.value)} value={searchModel} />
        <Input
          type="select"
          options={selectCategoryOption}
          label="Category"
          onChange={(e) => setSearchCategory(e.target.value)}
          value={searchCategory}
        />
        <Input
          type="select"
          options={selectBrandOption}
          label="Brand"
          onChange={(e) => setSearchBrand(e.target.value)}
          value={searchBrand}
        />
      </div>
      <div className="flex justify-between">
        <button onClick={getData} className="btn btn-sm btn-secondary">
          Search
        </button>
        <button onClick={() => navigate("/products/new")} className="btn btn-sm btn-primary">
          AddNew
        </button>
      </div>
      <div className="overflow-x-auto mt-3">
        <table className="table table-zebra">
          {/* head */}
          <thead className="bg-primary text-white text-lg capitalize">
            <tr>
              <th>Image</th>
              <th>Modal</th>
              <th>Code</th>
              <th>Brand</th>
              <th>Category</th>
              <th>Price</th>
              <th>Specification</th>
              <th>Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {products.length === 0 && (
              <tr>
                <td colSpan={9} className="text-center font-semibold text-lg md:text-xl capitalize py-3">
                  No products found.
                </td>
              </tr>
            )}
            {products.map((product, index) => (
              <tr key={index}>
                <td className="w-[120px] h-[100px]">
                  <img
                    onClick={() => {
                      setShowImageModal(true);
                      imageSourceRef.current = get_server_image(product.image);
                    }}
                    className="w-[100%] h-[100%] cursor-pointer"
                    alt={product.model}
                    src={get_server_image(product.image)}
                    loading="lazy"
                  />
                </td>
                <td>{product.model}</td>
                <td>{product.code}</td>
                <td>{product.brand}</td>
                <td>{product.category}</td>
                <td>{product.price}</td>
                <td>
                  <Specification productID={product.id} />
                </td>

                <td>
                  {product?.note?.split(",").map((note, index) => (
                    <div key={index}>- {note}</div>
                  ))}
                </td>
                <td>
                  <div className="flex gap-2">
                    <button
                      onClick={() => {
                        productIdRef.current = product.id;
                        setShowConfirmCopy(true);
                      }}
                      className="btn btn-xs btn-success text-white"
                    >
                      <FontAwesomeIcon title="copy" icon={faCopy} />
                    </button>
                    <button onClick={() => navigate("/products/edit/" + product.id)} className="btn btn-xs btn-primary">
                      <FontAwesomeIcon title="edit" icon={faEdit} />
                    </button>
                    <ConfirmDelete
                      onDelete={() => {
                        request({ url: "products/" + product.id, method: "DELETE" }).then((res) => {
                          setProducts((current) => current.filter((p) => p.id !== product.id));
                        });
                      }}
                    >
                      <span className="btn btn-xs btn-error">
                        <FontAwesomeIcon title="delete" className="text-white" icon={faTrash} />
                      </span>
                    </ConfirmDelete>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal open={showImageModal} onClose={() => setShowImageModal(false)} size="md" full>
        <div className="relative w-full h-[400px] md:h-[600px]">
          <img className="w-[100%] h-[100%]" src={imageSourceRef.current} alt="..." />
        </div>
      </Modal>

      <Modal open={showConfirmCopy} onClose={() => setShowConfirmCopy(false)}>
        <h3 className="text-lg md:text-2xl font-semibold mb-3">Confirm Copy</h3>
        <p className="text-center my-3">Are you sure want to copy this?</p>
        <div className="flex gap-3 justify-end">
          <button
            onClick={async () => {
              if (productIdRef.current) {
                const result = await request({
                  method: "POST",
                  url: "products/clone/" + productIdRef.current,
                });
                const new_product: Products = result.data;
                setProducts((current) => [...current, new_product]);
              }
              setShowConfirmCopy(false);
            }}
            className="btn btn-sm btn-primary"
          >
            Confirm
          </button>
          <button onClick={() => setShowConfirmCopy(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>
    </section>
  );
}
