import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { ConfirmDelete, Input, Modal, Loading } from "../../../components";
import { request } from "../../../lib";

export default function Brands() {
  const [newBrand, setNewBrand] = useState("");
  const [editBrand, setEditBrand] = useState("");
  const [saveError, setSaveError] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [brands, setBrands] = useState<Brands[]>([]);

  const [ready, setReady] = useState(false);
  const selectedRef = useRef<number>(0);

  useEffect(() => {
    request({ url: "brands" })
      .then((res) => setBrands(res.data))
      .finally(() => setReady(true));
  }, []);

  function saveNew() {
    if (!newBrand) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    //process save
    request({ method: "POST", url: "brands", data: { brand: newBrand } })
      .then((res) => {
        setBrands((current) => [...current, res.data]);
      })
      .finally(() => {
        setNewBrand("");
        setShowNewModal(false);
      });
  }
  function saveEdit() {
    if (!editBrand) {
      setSaveError(true);
      return;
    }
    setSaveError(false);
    //process save
    request({ method: "PUT", url: "brands/" + selectedRef.current, data: { brand: editBrand } })
      .then((res) => {
        const index = brands.findIndex((brand) => brand.id === selectedRef.current);
        if (index > 0) {
          const updateData: Brands = {
            id: selectedRef.current,
            brand: editBrand,
          };
          setBrands((current) => [...current.slice(0, index), updateData, ...current.slice(index + 1)]);
        }
      })
      .finally(() => {
        setEditBrand("");
        setShowEditModal(false);
      });
  }
  function deleteBrand(id: number | undefined) {
    if (!id) return;
    //request to backend delete
    request({ method: "DELETE", url: "brands/" + id }).then((res) => setBrands((current) => current.filter((b) => b.id !== id)));
  }

  if (!ready) return <Loading />;
  return (
    <section className="px-3 sm:px-0 sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto">
      <h3 className="font-semibold text-lg md:text-2xl text-center my-3">Brands</h3>
      <div className="text-end">
        <button onClick={() => setShowNewModal(true)} className="btn btn-sm btn-primary my-3">
          Addnew
        </button>
        <div className="overflow-x-auto mt-3">
          <table className="table table-zebra">
            <thead className="bg-primary text-white text-lg capitalize">
              <tr>
                <td className="w-[10%]">No</td>
                <td>Brands</td>
                <td className="w-[20%]">Action</td>
              </tr>
            </thead>
            <tbody>
              {brands.length === 0 && (
                <tr>
                  <td colSpan={3} align="center" className="font-semibold">
                    No data
                  </td>
                </tr>
              )}
              {brands.map((brand, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{brand.brand}</td>
                  <td className="flex gap-3">
                    <button
                      onClick={() => {
                        selectedRef.current = brand.id;
                        setEditBrand(brand.brand);
                        setShowEditModal(true);
                      }}
                      className="btn btn-xs btn-primary"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <ConfirmDelete className="btn btn-xs btn-error" onDelete={() => deleteBrand(brand.id)}>
                      <FontAwesomeIcon className="text-white" icon={faTrash} />
                    </ConfirmDelete>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* new modal */}
      <Modal open={showNewModal} onClose={() => setShowNewModal(false)}>
        <h3 className="mb-3 font-semibold text-center text-lg md:text-2xl">New Brand</h3>
        <Input label="Brand" value={newBrand} onChange={(e) => setNewBrand(e.target.value)} required />
        {saveError && <div className="text-end text-error font-semibold my-3">* field is required.</div>}
        <div className="flex justify-end gap-3">
          <button onClick={saveNew} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowNewModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>

      {/* edit modal */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <h3 className="mb-3 font-semibold text-center text-lg md:text-2xl">Edit Brand</h3>
        <Input label="Brand" value={editBrand} onChange={(e) => setEditBrand(e.target.value)} required />
        {saveError && <div className="text-end text-red-500 font-semibold my-3">* field is required.</div>}
        <div className="flex justify-end gap-3">
          <button onClick={saveEdit} className="btn btn-sm btn-primary">
            Save
          </button>
          <button onClick={() => setShowEditModal(false)} className="btn btn-sm btn-outline">
            Cancel
          </button>
        </div>
      </Modal>
    </section>
  );
}
