import { useEffect, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAngleUp, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { ProductCard, Footer, Carousel, Loading } from "../components";
import { request, static_asset } from "../lib";

export default function Homepage() {
  const [showQuickAccess, setShowQuickAccess] = useState(true);
  const [showQuickScroll, setShowQuickScroll] = useState(false);
  const [productByBrands, setProductByBrands] = useState<ProductByBrand[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);

  const [ready, setReady] = useState(false);
  const selectedCategoryRef = useRef<number>(0);
  const productRef = useRef<Products[]>([]);
  const brandRef = useRef<Brands[]>([]);

  function getProduct(categoryID: number) {
    setReady(false);
    // filter out product by category
    const productByCategory = productRef.current.filter((p) => p.category_id === categoryID);

    const data: ProductByBrand[] = [];
    brandRef.current.forEach((b) => {
      const productByBrand = productByCategory.filter((p) => p.brand_id === b.id);
      if (productByBrand.length > 0) data.push({ brand: b.brand, products: productByBrand });
    });
    setProductByBrands(data);
    setReady(true);
  }

  useEffect(() => {
    (async () => {
      //get products
      const product_result = await request({ url: "products" });
      productRef.current = product_result.data;

      //get all brand
      const brand_result = await request({ url: "brands" });
      brandRef.current = brand_result.data;

      //get all category
      const category_result = await request({ url: "categories" });
      const category_data: Categories[] = category_result.data;
      setCategories(category_data);

      //set selected first category
      if (category_data.length > 0) selectedCategoryRef.current = category_data[0].id;
      getProduct(selectedCategoryRef.current);
    })();

    window.onscroll = () => {
      setShowQuickScroll(window.scrollY === 0 ? false : true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="bg-slate-300 pt-0  md:pt-6">
      <section className="flex min-h-screen flex-col w-full md:w-[80%] lg:w-[70%] mx-auto bg-white ">
        <nav className="">
          <div className="md:flex w-full">
            <div className="flex md:block min-w-[40%]">
              <div className="w-full">
                <img className="w-[100%] h-[100%]" src={static_asset("/assets/images/logo/logo_1.png")} alt="logo" />
              </div>
              <div className="w-full">
                <img className="w-[100%] h-[100%]" src={static_asset("/assets/images/logo/logo_2.gif")} alt="logo" />
              </div>
            </div>
            <div className="grow">
              <Carousel
                items={[
                  static_asset("/assets/images/slide/slide_1.jpg"),
                  static_asset("/assets/images/slide/slide_2.jpg"),
                  static_asset("/assets/images/slide/slide_3.jpg"),
                  static_asset("/assets/images/slide/slide_4.jpg"),
                  static_asset("/assets/images/slide/slide_5.jpg"),
                ]}
              />
            </div>
          </div>
          <Marquee pauseOnHover={true} className="bg-slate-100 py-2 border-y-4 border-gray-400 ">
            shop info
          </Marquee>
          <div className="flex gap-[1px] pt-2 pb-1 border-b-2 border-red-500 pl-3 overflow-x-auto">
            {categories.map((pCategory, index) => {
              const isActive = selectedCategoryRef.current === pCategory.id ? "btn-active" : "btn-success";
              return (
                <button
                  onClick={() => {
                    getProduct(pCategory.id);
                    selectedCategoryRef.current = pCategory.id;
                  }}
                  key={index}
                  className={`btn btn-xs ${isActive}`}
                >
                  {pCategory.category}
                </button>
              );
            })}
          </div>
          <div className="w-full flex">
            <div className="min-w-[50%]">
              <img className="w-[100%] h-[100%]" src={static_asset("/assets/images/logo/logo_3.jpg")} alt="Main page" />
            </div>
            <div className="min-w-[50%]">
              <img className="w-[100%] h-[100%]" src={static_asset("/assets/images/logo/logo_4.jpg")} alt="Main page" />
            </div>
          </div>
          <div className="w-full">
            <img className="w-[100%] h-[100%]" src={static_asset("/assets/images/banner/banner_1.jpg")} alt="Main page" />
          </div>
        </nav>
        {!ready && <Loading />}
        {ready && (
          <div className="flex-1">
            {productByBrands.length === 0 && (
              <div className="text-center h-[200px] grid place-content-center mt-10 font-semibold text-lg md:text-2xl">
                No product display in this category
              </div>
            )}
            {productByBrands.map((pBrand, index) => {
              return (
                <div key={index} id={pBrand.brand.toLowerCase()}>
                  <div className="font-extrabold p-3 text-lg md:text-2xl bg-primary text-white">{pBrand.brand.toUpperCase()}</div>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {pBrand.products.map((product, index) => {
                      return <ProductCard key={index} {...product} />;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <Footer />
      </section>
      {showQuickScroll && (
        <div className="flex justify-end items-center bottom-0 right-0 fixed gap-[1px]">
          {productByBrands.length > 0 && (
            <FontAwesomeIcon
              onClick={() => setShowQuickAccess(!showQuickAccess)}
              icon={showQuickAccess ? faAnglesRight : faAnglesLeft}
              title={showQuickAccess ? "hide" : "show"}
              className="px-1 bg-black text-white font-semibold rounded-md cursor-pointer"
              size="xl"
            />
          )}
          {showQuickAccess &&
            productByBrands.map((pBrand, index) => (
              <button
                onClick={() => {
                  const element = document.getElementById(pBrand.brand.toLowerCase());
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="btn-xs px-3 py-1 bg-black text-white font-semibold rounded-md"
                key={index}
              >
                {pBrand.brand.toUpperCase()}
              </button>
            ))}
          <FontAwesomeIcon
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            title="Go to top"
            icon={faAngleUp}
            className="px-3 bg-black text-white font-semibold rounded-md cursor-pointer"
            size="xl"
          />
        </div>
      )}
    </main>
  );
}
