import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";

type CarouselProps = {
  items: string[];
  duration?: number;
  showControll?: boolean;
  showIndicator?: boolean;
};

export default function Carousel(props: CarouselProps) {
  const { items, duration = 5000, showControll = false, showIndicator = false } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  const lastIndex = items.length - 1;
  useEffect(() => {
    const timeOut = setTimeout(() => {
      next();
    }, duration);
    return () => {
      clearTimeout(timeOut);
    };
  });

  function next() {
    if (currentIndex === lastIndex) setCurrentIndex(0);
    else setCurrentIndex((current) => current + 1);
  }

  function prev() {
    if (currentIndex === 0) setCurrentIndex(lastIndex);
    else setCurrentIndex((current) => current - 1);
  }

  if (items.length === 0) return <></>;

  return (
    <div className="w-full h-full relative carousel transition ease-in-out delay-1000 duration-1000 ">
      <img alt="..." height="100%" width="100%" src={items[currentIndex]} />
      {showControll && (
        <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
          <button onClick={prev} className="btn btn-circle btn-sm md:btn-md">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button onClick={next} className="btn btn-circle btn-sm md:btn-md">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      )}

      {showIndicator && (
        <div className="absolute w-full bottom-1 flex justify-center gap-2">
          {items.map((item, index) => (
            <FontAwesomeIcon
              onClick={() => setCurrentIndex(index)}
              key={index}
              icon={faCircle}
              size={index === currentIndex ? "sm" : "xs"}
              color={index === currentIndex ? "#444" : "#fff"}
              style={{ cursor: "pointer" }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
